/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The cost of a room in points for a single night
 * @export
 * @interface PointCost
 */
export interface PointCost {
    /**
     * 
     * @type {number}
     * @memberof PointCost
     */
    points: number;
    /**
     * 
     * @type {string}
     * @memberof PointCost
     */
    roomType?: string;
    /**
     * 
     * @type {string}
     * @memberof PointCost
     */
    pointsType?: string;
    /**
     * 
     * @type {Date}
     * @memberof PointCost
     */
    checkin: Date;
    /**
     * 
     * @type {number}
     * @memberof PointCost
     */
    cashCost?: number;
    /**
     * 
     * @type {number}
     * @memberof PointCost
     */
    cashPercentile?: number;
    /**
     * 
     * @type {number}
     * @memberof PointCost
     */
    redemptionValuePercentile?: number;
}

/**
 * Check if a given object implements the PointCost interface.
 */
export function instanceOfPointCost(value: object): value is PointCost {
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('checkin' in value) || value['checkin'] === undefined) return false;
    return true;
}

export function PointCostFromJSON(json: any): PointCost {
    return PointCostFromJSONTyped(json, false);
}

export function PointCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointCost {
    if (json == null) {
        return json;
    }
    return {
        
        'points': json['points'],
        'roomType': json['room_type'] == null ? undefined : json['room_type'],
        'pointsType': json['points_type'] == null ? undefined : json['points_type'],
        'checkin': (new Date(json['checkin'])),
        'cashCost': json['cash_cost'] == null ? undefined : json['cash_cost'],
        'cashPercentile': json['cash_percentile'] == null ? undefined : json['cash_percentile'],
        'redemptionValuePercentile': json['redemption_value_percentile'] == null ? undefined : json['redemption_value_percentile'],
    };
}

export function PointCostToJSON(value?: PointCost | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'points': value['points'],
        'room_type': value['roomType'],
        'points_type': value['pointsType'],
        'checkin': ((value['checkin']).toISOString().substring(0,10)),
        'cash_cost': value['cashCost'],
        'cash_percentile': value['cashPercentile'],
        'redemption_value_percentile': value['redemptionValuePercentile'],
    };
}

