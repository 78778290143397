/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PointsAmount } from './PointsAmount';
import {
    PointsAmountFromJSON,
    PointsAmountFromJSONTyped,
    PointsAmountToJSON,
} from './PointsAmount';

/**
 * A stay summary - used in conjunction with a hotel summary
 * @export
 * @interface StaySummary
 */
export interface StaySummary {
    /**
     * 
     * @type {Date}
     * @memberof StaySummary
     */
    checkinDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StaySummary
     */
    checkoutDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof StaySummary
     */
    nights?: number;
    /**
     * Lowest total room rate
     * @type {number}
     * @memberof StaySummary
     */
    lowestRate?: number;
    /**
     * Currency of lowest rate and nightly rate
     * @type {string}
     * @memberof StaySummary
     */
    lowestRateCurrency?: string;
    /**
     * Average nightly rate for the lowest rate
     * @type {number}
     * @memberof StaySummary
     */
    nightlyRate?: number;
    /**
     * Lowest points for any room at hotel for the search range
     * @type {number}
     * @memberof StaySummary
     */
    lowestPoints?: number;
    /**
     * Average nightly points for the lowest points rate
     * @type {number}
     * @memberof StaySummary
     */
    lowestNightlyPoints?: number;
    /**
     * 
     * @type {PointsAmount}
     * @memberof StaySummary
     */
    lowestPointsAmount?: PointsAmount;
    /**
     * Distance from search location in m
     * @type {number}
     * @memberof StaySummary
     */
    proximity?: number;
    /**
     * The percentile of the points deal
     * @type {number}
     * @memberof StaySummary
     */
    pointsPercentile?: number;
    /**
     * The percentile of the cash deal
     * @type {number}
     * @memberof StaySummary
     */
    cashPercentile?: number;
    /**
     * The score of the deal. A value between 0 and 10, where 10 is a great deal.
     * @type {number}
     * @memberof StaySummary
     */
    cashDealScore?: number;
    /**
     * The score of the deal. A value between 0 and 10, where 10 is a great deal.
     * @type {number}
     * @memberof StaySummary
     */
    pointsDealScore?: number;
}

/**
 * Check if a given object implements the StaySummary interface.
 */
export function instanceOfStaySummary(value: object): value is StaySummary {
    return true;
}

export function StaySummaryFromJSON(json: any): StaySummary {
    return StaySummaryFromJSONTyped(json, false);
}

export function StaySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaySummary {
    if (json == null) {
        return json;
    }
    return {
        
        'checkinDate': json['checkin_date'] == null ? undefined : (new Date(json['checkin_date'])),
        'checkoutDate': json['checkout_date'] == null ? undefined : (new Date(json['checkout_date'])),
        'nights': json['nights'] == null ? undefined : json['nights'],
        'lowestRate': json['lowest_rate'] == null ? undefined : json['lowest_rate'],
        'lowestRateCurrency': json['lowest_rate_currency'] == null ? undefined : json['lowest_rate_currency'],
        'nightlyRate': json['nightly_rate'] == null ? undefined : json['nightly_rate'],
        'lowestPoints': json['lowest_points'] == null ? undefined : json['lowest_points'],
        'lowestNightlyPoints': json['lowest_nightly_points'] == null ? undefined : json['lowest_nightly_points'],
        'lowestPointsAmount': json['lowest_points_amount'] == null ? undefined : PointsAmountFromJSON(json['lowest_points_amount']),
        'proximity': json['proximity'] == null ? undefined : json['proximity'],
        'pointsPercentile': json['points_percentile'] == null ? undefined : json['points_percentile'],
        'cashPercentile': json['cash_percentile'] == null ? undefined : json['cash_percentile'],
        'cashDealScore': json['cash_deal_score'] == null ? undefined : json['cash_deal_score'],
        'pointsDealScore': json['points_deal_score'] == null ? undefined : json['points_deal_score'],
    };
}

export function StaySummaryToJSON(value?: StaySummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'checkin_date': value['checkinDate'] == null ? undefined : ((value['checkinDate']).toISOString().substring(0,10)),
        'checkout_date': value['checkoutDate'] == null ? undefined : ((value['checkoutDate']).toISOString().substring(0,10)),
        'nights': value['nights'],
        'lowest_rate': value['lowestRate'],
        'lowest_rate_currency': value['lowestRateCurrency'],
        'nightly_rate': value['nightlyRate'],
        'lowest_points': value['lowestPoints'],
        'lowest_nightly_points': value['lowestNightlyPoints'],
        'lowest_points_amount': PointsAmountToJSON(value['lowestPointsAmount']),
        'proximity': value['proximity'],
        'points_percentile': value['pointsPercentile'],
        'cash_percentile': value['cashPercentile'],
        'cash_deal_score': value['cashDealScore'],
        'points_deal_score': value['pointsDealScore'],
    };
}

