/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RateType } from './RateType';
import {
    RateTypeFromJSON,
    RateTypeFromJSONTyped,
    RateTypeToJSON,
} from './RateType';

/**
 * A request to book a hotel room
 * @export
 * @interface BookingRequest
 */
export interface BookingRequest {
    /**
     * If set to `true`, the booking will not be processed
     * @type {boolean}
     * @memberof BookingRequest
     */
    test?: boolean;
    /**
     * IP address from client.
     * @type {string}
     * @memberof BookingRequest
     */
    customerIp: string;
    /**
     * If the rate type is 'expedia', then this is the expedia booking token. If the rate type is 'direct', then this is the rate id.
     * @type {string}
     * @memberof BookingRequest
     */
    bookingToken: string;
    /**
     * Given name of individual booking the room
     * @type {string}
     * @memberof BookingRequest
     */
    givenName: string;
    /**
     * Family name of individual booking the room
     * @type {string}
     * @memberof BookingRequest
     */
    familyName: string;
    /**
     * Email address of individual booking the room
     * @type {string}
     * @memberof BookingRequest
     */
    emailAddress: string;
    /**
     * Phone number including country code
     * @type {string}
     * @memberof BookingRequest
     */
    phoneNumber: string;
    /**
     * Special request to be passed onto the hotel for booking
     * @type {string}
     * @memberof BookingRequest
     */
    specialRequest?: string;
    /**
     * Payment ID referencing a payment_method on the trips service
     * @type {string}
     * @memberof BookingRequest
     */
    paymentId: string;
    /**
     * Use available credits if available. If set to `true`, and there are credits available, `payment_id` will be ignored.
     * @type {boolean}
     * @memberof BookingRequest
     */
    useAvailableCredits?: boolean;
    /**
     * 
     * @type {RateType}
     * @memberof BookingRequest
     */
    rateType?: RateType;
}

/**
 * Check if a given object implements the BookingRequest interface.
 */
export function instanceOfBookingRequest(value: object): value is BookingRequest {
    if (!('customerIp' in value) || value['customerIp'] === undefined) return false;
    if (!('bookingToken' in value) || value['bookingToken'] === undefined) return false;
    if (!('givenName' in value) || value['givenName'] === undefined) return false;
    if (!('familyName' in value) || value['familyName'] === undefined) return false;
    if (!('emailAddress' in value) || value['emailAddress'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('paymentId' in value) || value['paymentId'] === undefined) return false;
    return true;
}

export function BookingRequestFromJSON(json: any): BookingRequest {
    return BookingRequestFromJSONTyped(json, false);
}

export function BookingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'test': json['test'] == null ? undefined : json['test'],
        'customerIp': json['customer_ip'],
        'bookingToken': json['booking_token'],
        'givenName': json['given_name'],
        'familyName': json['family_name'],
        'emailAddress': json['email_address'],
        'phoneNumber': json['phone_number'],
        'specialRequest': json['special_request'] == null ? undefined : json['special_request'],
        'paymentId': json['payment_id'],
        'useAvailableCredits': json['use_available_credits'] == null ? undefined : json['use_available_credits'],
        'rateType': json['rate_type'] == null ? undefined : RateTypeFromJSON(json['rate_type']),
    };
}

export function BookingRequestToJSON(value?: BookingRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'test': value['test'],
        'customer_ip': value['customerIp'],
        'booking_token': value['bookingToken'],
        'given_name': value['givenName'],
        'family_name': value['familyName'],
        'email_address': value['emailAddress'],
        'phone_number': value['phoneNumber'],
        'special_request': value['specialRequest'],
        'payment_id': value['paymentId'],
        'use_available_credits': value['useAvailableCredits'],
        'rate_type': RateTypeToJSON(value['rateType']),
    };
}

