/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';
import type { Viewport } from './Viewport';
import {
    ViewportFromJSON,
    ViewportFromJSONTyped,
    ViewportToJSON,
} from './Viewport';

/**
 * 
 * @export
 * @interface SearchSessionV2ResultsHotels
 */
export interface SearchSessionV2ResultsHotels {
    /**
     * 
     * @type {Array<HotelSummary>}
     * @memberof SearchSessionV2ResultsHotels
     */
    items?: Array<HotelSummary>;
    /**
     * 
     * @type {number}
     * @memberof SearchSessionV2ResultsHotels
     */
    numberOfItems: number;
    /**
     * All amenities available in the search results
     * @type {Array<string>}
     * @memberof SearchSessionV2ResultsHotels
     */
    availableAmenities?: Array<string>;
    /**
     * All image categories available in the search results
     * @type {Array<string>}
     * @memberof SearchSessionV2ResultsHotels
     */
    availableImageCategories?: Array<string>;
    /**
     * 
     * @type {Viewport}
     * @memberof SearchSessionV2ResultsHotels
     */
    recommendedViewport?: Viewport;
    /**
     * 
     * @type {Viewport}
     * @memberof SearchSessionV2ResultsHotels
     */
    searchRegion?: Viewport;
}

/**
 * Check if a given object implements the SearchSessionV2ResultsHotels interface.
 */
export function instanceOfSearchSessionV2ResultsHotels(value: object): value is SearchSessionV2ResultsHotels {
    if (!('numberOfItems' in value) || value['numberOfItems'] === undefined) return false;
    return true;
}

export function SearchSessionV2ResultsHotelsFromJSON(json: any): SearchSessionV2ResultsHotels {
    return SearchSessionV2ResultsHotelsFromJSONTyped(json, false);
}

export function SearchSessionV2ResultsHotelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSessionV2ResultsHotels {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(HotelSummaryFromJSON)),
        'numberOfItems': json['number_of_items'],
        'availableAmenities': json['available_amenities'] == null ? undefined : json['available_amenities'],
        'availableImageCategories': json['available_image_categories'] == null ? undefined : json['available_image_categories'],
        'recommendedViewport': json['recommended_viewport'] == null ? undefined : ViewportFromJSON(json['recommended_viewport']),
        'searchRegion': json['search_region'] == null ? undefined : ViewportFromJSON(json['search_region']),
    };
}

export function SearchSessionV2ResultsHotelsToJSON(value?: SearchSessionV2ResultsHotels | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(HotelSummaryToJSON)),
        'number_of_items': value['numberOfItems'],
        'available_amenities': value['availableAmenities'],
        'available_image_categories': value['availableImageCategories'],
        'recommended_viewport': ViewportToJSON(value['recommendedViewport']),
        'search_region': ViewportToJSON(value['searchRegion']),
    };
}

